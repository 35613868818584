import React, { FunctionComponent, useState } from 'react';
import Section from "../components/Section";
import Heading from "../components/Heading";
import Hero from "../components/Hero";
import OrderForm from "../forms/OrderForm";
import Button from '../components/Button/Button';
import useTranslations from '../components/useTranslations';

type orderFormProps = {
};

const orderForm: FunctionComponent<orderFormProps> = (props) => {
    const [success, setSuccess] = useState<boolean>(false)
    const { getStarted, orderForm } = useTranslations()
    return (
        <>
            <Section className={'py-20'}>
                <Hero
                    outerContainer
                    headline={`${getStarted}...`}
                    subheadline={orderForm.text}
                />
            </Section>
            <Section className={''}>
                <div className={success ? "bg-success py-6 px-20 rounded-md md:w-3/5 mx-auto mb-10" : 'w-full md:w-1/2 lg:w-3/5 xl:w-2/5 mx-auto mb-10'}>
                    {
                        success ?
                            (
                                <div className={'text-center m-auto'}>
                                    <Heading type='h4' className={'font-bold'}>
                                        {orderForm.success.text1}
                                    </Heading>
                                    <Heading type='h4' className={'mb-8 font-bold'}>
                                        {orderForm.success.text2}
                                    </Heading>
                                </div>
                            ) :
                            (
                                <OrderForm setSuccess={setSuccess} />
                            )
                    }

                </div>
            </Section>
        </>
    );
};

export default orderForm;
