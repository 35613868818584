import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { Form, Formik } from "formik";
import FormField from "../components/FormField";
import Button from "../components/Button/Button";
import * as Yup from "yup";
import FormSelectField from "../components/FormSelectField";
import useTranslations from '../components/useTranslations';

type OrderFormProps = {
    setSuccess: Dispatch<SetStateAction<boolean>>
};

type OrderFormValues = {
    email: string,
    fullName: string,
    phone: string,
    // country: any,
    request: string,
};

// const countries = [
//     { id: 'sk', name: 'Slovakia' },
//     { id: 'cz', name: 'Czech Republic' },
//     { id: 'hu', name: 'Hungary' },
//     { id: 'at', name: 'Austria' },
// ];

const OrderForm: FunctionComponent<OrderFormProps> = ({ setSuccess, ...props }) => {
    const { form, actions } = useTranslations();

    const initialValues: OrderFormValues = {
        email: '',
        fullName: '',
        phone: '',
        // country: null,
        request: '',
    };

    const OrderFormValidation = Yup.object().shape({
        email: Yup.string().email(form.error.email.invalid).required(form.required),
        fullName: Yup.string()
            .min(2, form.error.fullName.short)
            .max(50, form.error.fullName.long)
            .required(form.required),
        phone: Yup.string().matches(/^(\+[1234]{3})([\d]{9})$/, { message: form.error.phone.matches }).required(form.required),
        // country: Yup.mixed().required(form.required),
        request: Yup.string().required(form.required)
    });

    const handleSubmit = async (values: OrderFormValues) => {
        setSuccess(true)

        const response = await (await fetch(`/.netlify/functions/sendEmail`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({ title: "LEGALIA - ORDER FORM", ...values })
        })).json();

        setSuccess(true)
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={OrderFormValidation}
                onSubmit={handleSubmit}
            >
                {({ errors, touched }) => (
                    <Form>
                        <div className={'space-y-6 mb-6'}>
                            <FormField name="email" type="text" label={form.label.email} markRequired placeholder={form.placeholder.email} />
                            <FormField name="fullName" type="text" label={form.label.fullName} markRequired placeholder={form.placeholder.fullName} />
                            <FormField
                                name="phone"
                                type="text"
                                label={form.label.phone}
                                placeholder={form.placeholder.phone}
                                hint={form.hint.phone}
                                markRequired
                            />
                            {/* <FormSelectField
                                name="country"
                                label={'Country'}
                                markRequired
                                options={countries}
                            /> */}

                            <FormField name="request" type="textarea" label={form.label.message} markRequired placeholder={form.placeholder.message} />
                        </div>
                        <Button chevronVariant={'white'} type="submit" variant={'secondary'}>{actions.send}</Button>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default OrderForm;
